import styled from '@mui/material/styles/styled'
import { StyledLinkAsButton } from '@components/UI/Button/LinkAsButton'
import { StyledButton } from '@components/UI/Button/Button'

export const WrapperPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'mobileMinHeight',
})<{ mobileMinHeight: number }>(({ mobileMinHeight, theme }) => ({
  height: mobileMinHeight,
  width: '100%',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  display: 'flex',
  backgroundColor: theme.palette.background.dark.primary,
  color: theme.palette.text.light.primary,
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    height: 40,
  },
  [theme.breakpoints.down('sm')]: {
    height: 48,
  },
}))

export const ContentPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Content',
})(({ theme }) => ({
  height: 'inherit',
  width: 'inherit',
  display: 'flex',
  padding: `0 ${theme.spacing(4)}`,
}))

export const ContentSlide = styled('div', {
  name: 'PromotionBar',
  slot: 'ContentSlide',
})(({ theme }) => ({
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  height: 'inherit',
  alignItems: 'center',
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 600,
  lineHeight: 1.33,
  justifyContent: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    width: 'auto',
  },
}))

export const LinkPromotionBar = styled(StyledLinkAsButton, {
  name: 'PromotionBar',
  slot: 'Link',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  borderColor: theme.palette.text.light.primary,
  padding: '0.25rem 1rem',
  height: '1rem',
  minWidth:'2rem',
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down('lg')]: {
    width: 'auto',
    padding: '0.3rem 1rem',
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.grey,
    borderColor: theme.palette.text.light.primary,
  }
  
}))

export const ButtonPromotionBar = styled(StyledButton, {
  name: 'PromotionBar',
  slot: 'Button',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  borderColor: theme.palette.text.light.primary,
  padding: '0.8rem 1rem',
  height: '1.2rem',
  minWidth: '4rem',
  [theme.breakpoints.down('lg')]: {
    width: 'auto',
    padding: '0.7rem 1rem',
  },
  fontWeight: theme.typography.fontWeightBold,
  '&:hover': {
    backgroundColor: theme.palette.custom.grey,
    borderColor: theme.palette.text.light.primary,
  }
}))
